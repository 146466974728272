var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-group-detail" },
    [
      _c("Header", [
        _c("i", {
          staticClass: "me-icon-back",
          attrs: { slot: "left-one" },
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          },
          slot: "left-one"
        }),
        _c("i", {
          staticClass: "me-icon-addFriend",
          attrs: { slot: "right-one" },
          on: {
            click: function($event) {
              _vm.isGroupMember ? _vm.handlerAddUserToGroup() : ""
            }
          },
          slot: "right-one"
        })
      ]),
      _c(
        "div",
        { staticClass: "chat-group-detail__content" },
        [
          _c(
            "van-cell-group",
            { staticClass: "name" },
            [
              _c("van-cell", [
                _c(
                  "div",
                  {
                    staticClass: "van-cell__title--baseinfo",
                    attrs: { slot: "title" },
                    slot: "title"
                  },
                  [
                    _c("Avatar", {
                      attrs: {
                        src: _vm.groupInfo.group_header,
                        isGroup: "",
                        width: "60px",
                        height: "60px"
                      }
                    }),
                    _c("div", { staticClass: "info" }, [
                      _c("h3", [_vm._v(_vm._s(_vm.groupInfo.group_name))]),
                      _c("span", [_vm._v(_vm._s(_vm.groupInfo.join_time))])
                    ])
                  ],
                  1
                ),
                _c("i", {
                  staticClass: "me-icon-edit",
                  attrs: { slot: "right-icon" },
                  on: {
                    click: function($event) {
                      _vm.isGroupMember ? _vm.handlerEditGroupName() : ""
                    }
                  },
                  slot: "right-icon"
                })
              ])
            ],
            1
          ),
          _c(
            "van-cell-group",
            { staticClass: "tag" },
            [
              _c("van-cell", {
                attrs: { title: _vm.typeText, "is-link": "" },
                on: {
                  click: function($event) {
                    return _vm.$router.push({ name: "chatGroupInfo" })
                  }
                }
              })
            ],
            1
          ),
          _c(
            "van-cell-group",
            { staticClass: "members" },
            _vm._l(_vm.groupInfo.members, function(user, index) {
              return _c(
                "van-swipe-cell",
                { key: index },
                [
                  _c(
                    "van-cell",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$router.push({
                            name: "user",
                            params: { id: user.provider_id }
                          })
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "user" },
                        [
                          _c("Avatar", {
                            attrs: {
                              src: user.profile_photo,
                              width: "40px",
                              height: "40px",
                              id: user.provider_id
                            }
                          }),
                          _c("span", [_vm._v(_vm._s(user.name))])
                        ],
                        1
                      ),
                      _vm.groupInfo.group_owner === user.provider_id
                        ? _c("i", {
                            staticClass: "me-icon-user owner",
                            attrs: { slot: "right-icon" },
                            slot: "right-icon"
                          })
                        : _vm._e()
                    ]
                  ),
                  _vm.groupInfo.group_owner != user.provider_id &&
                  _vm.groupInfo.group_owner === _vm.loginUser.id
                    ? _c(
                        "div",
                        {
                          staticClass: "btns",
                          attrs: { slot: "right" },
                          slot: "right"
                        },
                        [
                          _c("van-button", {
                            attrs: { type: "danger", icon: "delete" },
                            on: {
                              click: function($event) {
                                return _vm.handlerRemoveMember(user.provider_id)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm.isGroupMember
        ? _c("div", { staticClass: "chat-group-detail__footer" }, [
            _c("i", {
              staticClass: "me-icon-copy default",
              attrs: { slot: "icon" },
              on: { click: _vm.handlerCopyDialog },
              slot: "icon"
            }),
            _c("i", {
              staticClass: "me-icon-quit primary",
              attrs: { slot: "icon" },
              on: { click: _vm.handlerRemoveGroup },
              slot: "icon"
            })
          ])
        : _vm._e(),
      _c(
        "van-dialog",
        {
          attrs: {
            "show-cancel-button": "",
            confirmButtonText: _vm.$t("done"),
            cancelButtonText: _vm.$t("cancle")
          },
          on: { confirm: _vm.handlerUpdateGroupName },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c("van-field", {
            staticClass: "editName",
            attrs: { type: "textarea", autofocus: "", row: "2" },
            model: {
              value: _vm.newName,
              callback: function($$v) {
                _vm.newName = $$v
              },
              expression: "newName"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }