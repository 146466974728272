<template>
  <div class="chat-group-detail">
    <Header>
      <i slot="left-one" class="me-icon-back" @click="$router.go(-1)"></i>
      <i slot="right-one" class="me-icon-addFriend" @click="isGroupMember ? handlerAddUserToGroup():''"></i>
    </Header>
    <div class="chat-group-detail__content">
      <van-cell-group class="name">
        <van-cell>
          <div slot="title" class="van-cell__title--baseinfo">
            <Avatar :src="groupInfo.group_header" isGroup width="60px" height="60px" />
            <div class="info">
              <h3>{{ groupInfo.group_name }}</h3>
              <span>{{ groupInfo.join_time }}</span>
            </div>
          </div>

          <i slot="right-icon"  @click="isGroupMember ? handlerEditGroupName():''" class="me-icon-edit"></i>
        </van-cell>
      </van-cell-group>
      <van-cell-group class="tag">
        <van-cell :title="typeText" @click="$router.push({name:'chatGroupInfo'})" is-link />
      </van-cell-group>
      <van-cell-group class="members">
        <van-swipe-cell v-for="(user, index) in groupInfo.members"  :key="index">
          <van-cell @click="$router.push({name:'user',params:{id:user.provider_id}})"
          >
            <div class="user">
              <Avatar :src="user.profile_photo" width="40px" height="40px" :id="user.provider_id" />
              <span>{{ user.name }}</span>
            </div>
            <i slot="right-icon"
              class="me-icon-user owner"
              v-if="groupInfo.group_owner === user.provider_id"
            />
          </van-cell>
          <div slot="right" class="btns" v-if="groupInfo.group_owner != user.provider_id && groupInfo.group_owner ===loginUser.id">
            <van-button type="danger" icon="delete" @click="handlerRemoveMember(user.provider_id)"></van-button>
          </div>
        </van-swipe-cell>
      </van-cell-group>
    </div>
    <div class="chat-group-detail__footer" v-if="isGroupMember">
      <i slot="icon" class="me-icon-copy default" @click="handlerCopyDialog"></i>
      <i slot="icon" class="me-icon-quit primary" @click="handlerRemoveGroup"></i>
    </div>
    <van-dialog v-model="show" show-cancel-button @confirm="handlerUpdateGroupName" :confirmButtonText="$t('done')"
          :cancelButtonText="$t('cancle')">
      <van-field v-model="newName" type="textarea" autofocus class="editName" row="2"></van-field>
    </van-dialog>
  </div>
</template>
<script>
import Header from "@/components/business/h5/header";
import Avatar from "@/components/common/avatar";
import { Base64 } from "js-base64";
import {
  queryGroupInfoAndMembers,
  addSessionGroup,
  removeGroup,
  removeGroupMember,
  exitGroupMember,
  updateGroupName
} from "@/api/im";
export default {
  name: "chat-group-detail",
  data() {
    return {
      id: "",
      groupInfo: {},
      show: false,
      newName: "",
      curGroupType: "",
      typeText: "",
      loginUser:{},
      isGroupMember:false
    };
  },
  components: {
    Header,
    Avatar
  },
  created() {
    this.id = this.$route.params.id;
    this.loginUser = JSON.parse(localStorage.getItem('userinfo') || {})
    this.handlerGetGroupInfo();
  },
  methods: {
    async handlerRemoveMember(id){
      const language = localStorage.getItem('langCode') || '1'
      const result = await removeGroupMember(this.id,id,language)
      if(result && result.code == 1){
        this.$toast.success()
        this.groupInfo.members.some((el,index)=>{
          if(el.provider_id == id) this.groupInfo.members.splice(index,1)
        })
      }else{
        this.$toast.fail()
      }
      
      
    },
    handlerAddUserToGroup() {
      const oldMembers = this.groupInfo.members.map(item => {
        return {
          avatar: item.profile_photo,
          id: item.provider_id
        };
      });
      localStorage.setItem(
        "oldGroupMembers",
        JSON.stringify({ id: this.groupInfo.group_id, members: oldMembers })
      );
      this.$router.push({
        name: "friends",
        query: { selectModel: true }
      });
    },   
    handlerEditGroupName() {
      this.show = !this.show;
    },
    async handlerUpdateGroupName() {
      const params = {
        group_id: this.groupInfo.group_id,
        group_name: this.newName,
        token: JSON.parse(localStorage.getItem("userinfo")).token
      };
      const result = await updateGroupName(params);
      setTimeout(() => {
        this.handlerDone(result, true);
        this.show = false;
      }, 1000);
    },
    async handlerGetGroupInfo() {
      const result = await queryGroupInfoAndMembers(this.id);
      if (result && result.data) {
        this.groupInfo = result.data[0];
        this.newName = this.groupInfo.group_name;
        this.isGroupMember = this.groupInfo.members.some(el=>{          
          return el.provider_id === this.loginUser.id
        })
        this.curGroupType = this.groupInfo.group_type
          ? this.groupInfo.group_type
          : this.groupInfo.access_pattern == 3
          ? "4"
          : this.groupInfo.access_pattern;
        localStorage.setItem("groupType", this.curGroupType);
        this.typeText = this.$t("groupType")[this.curGroupType].name;
      }
    },
    handlerRemoveGroup() {
      this.$dialog
        .confirm({
          // title: "标题",
          message: `<i class="me-icon-delete dialogText" /><i class="me-icon-question dialogText" />`,
          confirmButtonText:this.$t('done'),
          cancelButtonText:this.$t('cancle')
        })
        .then(async () => {
          const userStr = localStorage.getItem("userinfo");
          const userinfo = JSON.parse(userStr);
          const isGroupOwner =
            userinfo.id === this.groupInfo.group_owner ? true : false;
          if (isGroupOwner) {
            const result = await removeGroup(this.groupInfo.group_id);
            this.handlerDone(result);
          } else {
            const result = await exitGroupMember(
              this.groupInfo.group_id,
              userinfo.id
            );
            this.handlerDone(result);
          }

          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    handlerDone(result, isBack) {
      if (result && result.code === 1) {
        this.$toast.success();
        if (isBack) {
          localStorage.setItem(
            "chatObj",
            JSON.stringify({
              id: this.groupInfo.group_id,
              name: this.newName,
              type: "group"
            })
          );
        }
      } else {
        this.$toast.fail();
      }
      if (isBack) this.$router.go(-1);
      else {
        this.$router.push({ name: "chat" });
        localStorage.removeItem("chatObj");
      }
    },
    handlerCopyDialog() {
      this.$dialog
        .confirm({
          // title: "标题",
          message: `<i class="me-icon-copy dialogText" /><i class="me-icon-question dialogText" />`,
          confirmButtonText:this.$t('done'),
          cancelButtonText:this.$t('cancle')
        })
        .then(async () => {
          const ids = this.groupInfo.members.map(item => {
            return item.provider_id;
          });
          const name = this.groupInfo.group_name + "(1)";
          const result = await addSessionGroup(ids, [name]);
          if (result && result.data) {
            const { group_id } = result.data[0];
            const idStr = group_id.toString();

            localStorage.setItem(
              "chatObj",
              JSON.stringify({
                id: idStr,
                name,
                type: "group"
              })
            );
            this.$router.push({
              name: "chatWith",
              params: { 
                id: "pai_" + Base64.encode(idStr)
              }
            });
          }
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    }
  }
};
</script>

<style lang="stylus">
@import '~styles/utils/index';

+b(editName) {
  .van-cell__value {
    .van-field__body {
      border: 1px solid #33cc66;
      padding: 0.5rem;
    }
  }
}

+b(van-dialog) {
  +e(content) {
    padding: 20px 0;

    .van-dialog__message {
      i {
        &.me-icon-copy, &.me-icon-delete {
          font-size: 36px;
        }
      }

      .dialogText {
        font-size: 24px;
        color: #999999;
      }
    }
  }

  +e(footer) {
    .van-dialog__confirm {
      color: #33cc66;
    }
  }
}

+b(chat-group-detail) {
  padding-top: 54px;
  min-height: 100vh;
  background: #efefef;
  position: relative;

  .name {
    position: fixed;
    width: 100%;
    z-index: 60;
    top: 54px;
  }

  .tag {
    margin-top: 90px;
  }

  .members {
    margin-top: 10px;

    .van-cell {
      align-items: center;

      .owner {
        height: 24px;

        &::before {
          background: #33cc66;
          padding: 5px;
          border-radius: 20px;
          color: #ffffff;
        }
      }
    }
  }

  +e(content) {
    margin-bottom: 80px;

    .van-cell {
      .me-icon-edit {
        font-size: 20px;
      }

      +e(value) {
        .user {
          display: flex;
          flex-flow: row;
          align-items: center;
        }
      }

      +e(title) {
        +m(baseinfo) {
          display: flex;
          flex-flow: row;

          .info {
            h3 {
              margin: 0;
            }
          }
        }
      }
    }
  }

  +e(footer) {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 10px solid #efefef;
    padding: 10px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-evenly;
    background: #ffffff;

    i {
      border-radius: 2rem;
      min-width: 33%;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 28px;

      &.default {
        color: #999999;
        background: #e5e5e5;
      }

      &.primary {
        color: #ffffff;
        background: #33cc66;
      }
    }
  }
}
</style>
